import React from "react"
import Layout from "../components/layout.en"
import "./partner.css"
import HomePageYAMLData from "../../content/home.en.yaml"

export default () => (
    <Layout pageId={"/en/partner"}>
        <div className='w-100 partner_banner'>
            <div className='container  h-100'>
                <div className='row align-items-center h-100 '>
                    <div className='col-12'>
                        <div className='col-auto px-0' style={{fontSize: 70, fontWeight: "bold", color: "#41474D"}}>
                            {HomePageYAMLData.partner_title}
                            {/*项目展示*/}
                        </div>
                        <div className='w-100'/>
                        <div>
                            <div style={{backgroundColor: "#41474D", height: 2, width: 196, marginTop: 10}}/>
                        </div>
                        <div className='w-100'/>
                        <div className='col-9' style={{
                            fontSize: 18,
                            lineHeight: "30px",
                            marginTop: 15,
                            backgroundColor: "rgba(255, 255, 255, 0.24)",
                        }}>
                            <div style={{
                                padding: 25,
                                color: "#454545",
                                textAlign: 'justify'
                            }}>
                                {/*我们实验室与各大高校科研机构、公司合作紧密，拥有强大的技术支持，能够为科研项目和产品的研发提供坚强的后盾。*/}
                                {HomePageYAMLData.partner_title_content}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid'>
            <div className='container'>
                <div className='container'>
                    <ul className="nav tag" id="myTab" role="tablist">
                        <li className="tag-item" role="presentation">
                            <div className="active" id="iat-tab" data-toggle="tab" data-target="#iat"
                                 role="tab" aria-controls="IAT-TOP" aria-selected="true"> IAT-TOP
                            </div>
                        </li>
                        <li className="tag-item" role="presentation">
                            <div id="CMOSS-2-tab" data-toggle="tab" data-target="#CMOSS-2"
                                 role="tab" aria-controls="CMOSS-2" aria-selected="false">CMOSS-2
                            </div>
                        </li>
                        <li className="tag-item" role="presentation">
                            <div id="VISTA-tab" data-toggle="tab" data-target="#VISTA"
                                 role="tab" aria-controls="VISTA" aria-selected="false">VISTA
                            </div>
                        </li>
                        <li className="tag-item" role="presentation">
                            <div id="CMOSS-tab" data-toggle="tab" data-target="#CMOSS"
                                 role="tab" aria-controls="CMOSS" aria-selected="false">CMOSS
                            </div>
                        </li>
                        <li className="tag-item" role="presentation">
                            <div id="CASSISS-tab" data-toggle="tab" data-target="#CASSISS"
                                 role="tab" aria-controls="CASSISS" aria-selected="false">CASSISS
                            </div>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="iat" role="tabpanel" aria-labelledby="iat-tab">
                            <div name="IAT-TOP" className="row align-items-center justify-content-center">
                                <div className='col-auto px-0'
                                     style={{fontSize: 30, color: "#454545", textAlign: "center", marginTop: 60}}>
                                    {HomePageYAMLData.partner_category_title_3}
                                    {/*CMOSS*/}
                                </div>
                                <div className='w-100'/>
                                <div className='col-auto px-0'
                                     style={{
                                         fontSize: 20,
                                         color: "#454545",
                                         textAlign: "center",
                                         marginTop: 10,
                                         lineHeight: "25px"
                                     }}>
                                    {HomePageYAMLData.partner_category_title_3_content_1}
                                </div>
                                <div className='w-100'/>
                                <div className='col-auto'
                                     style={{
                                         fontSize: 20,
                                         color: "#454545",
                                         textAlign: "center",
                                         marginTop: 10,
                                         lineHeight: "25px"
                                     }}>
                                    {HomePageYAMLData.partner_category_title_3_content_2}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between item-div'
                                 style={{marginTop: 60, marginBottom: 60}}>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_3_1}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_3_1_content}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_3_2}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_3_2_content}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_3_3}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_3_3_content}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_3_4}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_3_4_content}
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <div className="col-4 px-0">
                                    <div>
                                        <div
                                            className='control_msg_title'>{HomePageYAMLData.partner_category_title_3_5}</div>
                                        <div className='control_msg_content ' style={{marginTop: 38}}>
                                            {HomePageYAMLData.partner_category_title_3_5_content}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 px-0">
                                    <img src="/images/partner/IAT-TOP.jpg" className='img-fluid CMOSS_shadow'/>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center"
                                 style={{marginTop: 38, marginBottom: 38}}>
                                <div style={{fontSize: 30, color: "#41474D"}}>
                                    {HomePageYAMLData.partner_category_title_3_6}
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <div className='col-8'>
                                    <img src="/images/partner/IAT-TOP-2.png" className='img-fluid'/>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="CMOSS-2" role="tabpanel" aria-labelledby="CMOSS-2-tab">
                            <div name="CMOSS-2" className="row align-items-center justify-content-center">
                                <div className='col-auto px-0'
                                     style={{fontSize: 30, color: "#454545", textAlign: "center", marginTop: 60}}>
                                    {HomePageYAMLData.partner_category_title_4}
                                    {/*CMOSS*/}
                                </div>
                                <div className='w-100'/>
                                <div className='col-auto px-0'
                                     style={{
                                         fontSize: 20,
                                         color: "#454545",
                                         textAlign: "center",
                                         marginTop: 10,
                                         lineHeight: "25px"
                                     }}>
                                    {HomePageYAMLData.partner_category_title_4_content_1}
                                </div>
                                <div className='w-100'/>
                                <div className='col-auto'
                                     style={{
                                         fontSize: 20,
                                         color: "#454545",
                                         textAlign: "center",
                                         marginTop: 10,
                                         lineHeight: "25px"
                                     }}>
                                    {HomePageYAMLData.partner_category_title_4_content_2}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between item-div'
                                 style={{marginTop: 60, marginBottom: 60}}>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_4_1}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_4_1_content}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_4_2}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_4_2_content}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_4_3}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_4_3_content}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_4_4}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_4_4_content}
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <div className="col-4 px-0">
                                    <div>
                                        <div
                                            className='control_msg_title'>{HomePageYAMLData.partner_category_title_4_5}</div>
                                        <div className='control_msg_content ' style={{marginTop: 38}}>
                                            {HomePageYAMLData.partner_category_title_4_5_content}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 px-0">
                                    <img src="/images/partner/CMOSS-2.jpg" className='img-fluid CMOSS_shadow'/>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center"
                                 style={{marginTop: 38, marginBottom: 38}}>
                                <div style={{fontSize: 30, color: "#41474D"}}>
                                    {HomePageYAMLData.partner_category_title_4_6}
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <div className='col-8'>
                                    <img src="/images/partner/CMOSS-2-2.png" className='img-fluid'/>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="VISTA" role="tabpanel" aria-labelledby="VISTA-tab">
                            <div name="VISTA" className="row align-items-center justify-content-center">
                                <div className='col-auto px-0'
                                     style={{fontSize: 30, color: "#454545", textAlign: "center", marginTop: 60}}>
                                    {HomePageYAMLData.partner_category_title_5}
                                    {/*CMOSS*/}
                                </div>
                                <div className='w-100'/>
                                <div className='col-auto px-0'
                                     style={{
                                         fontSize: 20,
                                         color: "#454545",
                                         textAlign: "center",
                                         marginTop: 10,
                                         lineHeight: "25px"
                                     }}>
                                    {HomePageYAMLData.partner_category_title_5_content_1}
                                </div>
                                <div className='w-100'/>
                                <div className='col-auto'
                                     style={{
                                         fontSize: 20,
                                         color: "#454545",
                                         textAlign: "center",
                                         marginTop: 10,
                                         lineHeight: "25px"
                                     }}>
                                    {HomePageYAMLData.partner_category_title_5_content_2}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between item-div'
                                 style={{marginTop: 60, marginBottom: 60}}>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_5_1}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_5_1_content}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_5_2}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_5_2_content}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_5_3}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_5_3_content}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_5_4}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_5_4_content}
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <div className="col-4 px-0">
                                    <div>
                                        <div
                                            className='control_msg_title'>{HomePageYAMLData.partner_category_title_4_5}</div>
                                        <div className='control_msg_content ' style={{marginTop: 38}}>
                                            {HomePageYAMLData.partner_category_title_5_5_content}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 px-0">
                                    <img src="/images/partner/VISTA.jpg" className='img-fluid CMOSS_shadow'/>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center"
                                 style={{marginTop: 38, marginBottom: 38}}>
                                <div style={{fontSize: 30, color: "#41474D"}}>
                                    {HomePageYAMLData.partner_category_title_5_6}
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <div className='col-8'>
                                    <img src="/images/partner/VISTA-2.png" className='img-fluid'/>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="CMOSS" role="tabpanel" aria-labelledby="CMOSS-tab">
                            <div name="cmoss" className="row align-items-center justify-content-center">
                                <div className='col-auto px-0'
                                     style={{fontSize: 30, color: "#454545", textAlign: "center", marginTop: 60}}>
                                    {HomePageYAMLData.partner_category_title_2}
                                    {/*CMOSS*/}
                                </div>
                                <div className='w-100'/>
                                <div className='col-auto px-0'
                                     style={{
                                         fontSize: 20,
                                         color: "#454545",
                                         textAlign: "center",
                                         marginTop: 10,
                                         lineHeight: "25px"
                                     }}>
                                    {HomePageYAMLData.partner_category_title_2_content_1}
                                </div>
                                <div className='w-100'/>
                                <div className='col-auto'
                                     style={{
                                         fontSize: 20,
                                         color: "#454545",
                                         textAlign: "center",
                                         marginTop: 10,
                                         lineHeight: "25px"
                                     }}>
                                    {HomePageYAMLData.partner_category_title_2_content_2}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between item-div'
                                 style={{marginTop: 60, marginBottom: 60}}>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_2_1}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_2_1_content}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_2_2}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_2_2_content}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_2_3}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_2_3_content}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_2_4}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_2_4_content}
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <div className="col-4 px-0">
                                    <div>
                                        <div
                                            className='control_msg_title'>{HomePageYAMLData.partner_category_title_2_5}</div>
                                        <div className='control_msg_content ' style={{marginTop: 38}}>
                                            {HomePageYAMLData.partner_category_title_2_5_content}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 px-0">
                                    <img src="/images/partner/CMOSS.jpg" className='img-fluid CMOSS_shadow'/>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center"
                                 style={{marginTop: 38, marginBottom: 38}}>
                                <div style={{fontSize: 30, color: "#41474D"}}>
                                    {HomePageYAMLData.partner_category_title_2_6}
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <div className='col-8'>
                                    <img src="/images/partner/CMOSS-2.png" className='img-fluid'/>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="CASSISS" role="tabpanel" aria-labelledby="CASSISS-tab">
                            <div name="cassiss" className="row align-items-center justify-content-center"
                                 >
                                <div className='col-auto px-0'
                                     style={{fontSize: 30, color: "#454545", textAlign: "center", marginTop: 60}}>
                                    {/*CASSISS*/}
                                    {/*<a name="cassiss"></a>*/}
                                    {HomePageYAMLData.partner_category_title_1}
                                </div>
                                <div className='w-100'/>
                                <div className='col-auto px-0'
                                     style={{
                                         fontSize: 20,
                                         color: "#454545",
                                         textAlign: "center",
                                         marginTop: 10,
                                         lineHeight: "25px"
                                     }}>
                                    {/*China Angioplasty and Stenting for Symptomatic Intracranial Severe Stenosis*/}
                                    {HomePageYAMLData.partner_category_title_1_content_1}
                                </div>
                                <div className='w-100'/>
                                <div className='col-auto'
                                     style={{
                                         fontSize: 20,
                                         color: "#454545",
                                         textAlign: "center",
                                         marginTop: 10,
                                         lineHeight: "25px"
                                     }}>
                                    {HomePageYAMLData.partner_category_title_1_content_2}
                                    {/*ClinicalTrials Identifiers: NCT01763320*/}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between item-div'
                                 style={{marginTop: 60, marginBottom: 60}}>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {/*8*/}
                                        {HomePageYAMLData.partner_category_title_1_1}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_1_1_content}
                                        {/*三甲医院*/}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_1_2}
                                        {/*380*/}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_1_2_content}
                                        {/*患者*/}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_1_3}
                                        {/*2400+*/}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_1_3_content}
                                        {/*数据采集*/}
                                    </div>
                                </div>
                                <div className='item-flex'>
                                    <div className='item-flex-title'>
                                        {HomePageYAMLData.partner_category_title_1_4}
                                        {/*100,000+*/}
                                    </div>
                                    <div className='item-flex-msg'>
                                        {HomePageYAMLData.partner_category_title_1_4_content}
                                        {/*影像资料*/}
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <div className="col-6 px-0">
                                    <img src="/images/partner/CASSISS.jpg" className='img-fluid cassiss_shadow'/>
                                </div>
                                <div className="col-6 pl-2">
                                    <div>
                                        <div className='control_msg_title'>
                                            {HomePageYAMLData.partner_category_title_1_5}
                                        </div>
                                        <div className='control_msg_content' style={{marginTop: 38}}>
                                            {HomePageYAMLData.partner_category_title_1_5_content}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center"
                                 style={{marginTop: 38, marginBottom: 38}}>
                                <div style={{fontSize: 30, color: "#41474D"}}>
                                    {HomePageYAMLData.partner_category_title_1_6}
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <div className='col-8'>
                                    <img src="/images/partner/CASSISS-2.png" className='img-fluid '/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'w-100'} style={{height: 100}}/>
                </div>
            </div>
        </div>
    </Layout>
)
